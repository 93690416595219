function ThankYouPage() {
  return (
    <section className="panel text-center">
      <h2>Udało się!</h2>
      <div>Do wszystkich zostały wysłane e-maile z wynikami, miłej zabawy</div>
      <div className="mt-1" style={{ fontSize: "42px" }}>
        🎅
      </div>
    </section>
  );
}

export default ThankYouPage;
